import CongratulationsSection from './CongratulationsSection';
import PolicyInformationSection from './PolicyInformationSection';

const NewPolicyPage1 = ({ data }) => {
  return (
    <>
      <h1>
        Helseforsikring {data.answers.product === 'avonova-no-base' && 'Base'}
        {data.answers.product === 'avonova-no-pluss' && 'Pluss'}
        <br /> Forsikringsbevis
      </h1>
      <p>2024</p>
      <PolicyInformationSection data={data} />
      <CongratulationsSection />
    </>
  );
};

export default NewPolicyPage1;
