import ChangesInInsuranceSection from './ChangesInInsuranceSection';
import PolicyInformationSection from './PolicyInformationSection';

const RenewedPolicyPage1 = ({ data }) => {
  return (
    <>
      <h1>
        Helseforsikring {data.answers.product === 'avonova-no-base' && 'Base'}
        {data.answers.product === 'avonova-no-pluss' && 'Pluss'}
        <br /> Forsikringsbevis
      </h1>
      <p>2024</p>
      <PolicyInformationSection data={data} />
      <ChangesInInsuranceSection />
    </>
  );
};

export default RenewedPolicyPage1;
